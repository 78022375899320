<template>
  <div>
    <b-row>
      <b-col cols="2">
        <b-form-input v-model="filter" placeholder="Search..."/>
      </b-col>
    </b-row>
    <b-table class="mt-4" striped hover :items="sortByName(filteredItems)" :fields="fields"></b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalFilteredItems.length"
      :per-page="perPage"/>
  </div>
</template>

<script>
export default {
  name: 'MappingTable',
  props: {
    mapping: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredItems() {
      return this.mapping
        .filter(item => item.name.toLowerCase().includes(this.filter.toLowerCase()))
        .slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    totalFilteredItems() {
      return this.mapping
        .filter(item => item.name.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: '',
      fields: [
        { key: 'name', label: 'Column Name' },
        { key: 'header', label: 'File Header' },
        { key: 'column_number', label: 'Column Number' },
      ],
    };
  },
  methods: {
    sortByName(array) {
      return array.sort(
        (a, b) => a.name.localeCompare(b.name),
      );
    },
  },
};
</script>
