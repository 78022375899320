<template>
  <div class="metadata-container">
    <div class="metadata-row">
      <div class="metadata-label">Fingerprint Columns</div>
      <div class="metadata-value">{{ uniqueId }}</div>
    </div>
    <div v-if="fingerprintSettings">
      <div class="metadata-row">
        <div class="metadata-label">Override Children Fingerprint</div>
        <div class="metadata-value">
          <b-badge :variant="fingerprintSettings.override_children_fingerprint ? 'warning' : 'success'">
            {{ fingerprintSettings.override_children_fingerprint ? 'Yes' : 'No' }}
          </b-badge>
        </div>
      </div>
      <div v-if="fingerprintSettings.override_children_fingerprint">
        <div class="metadata-row">
          <div class="metadata-label">Parent ID</div>
          <div class="metadata-value">{{ fingerprintSettings.parent_id }}</div>
        </div>
        <div class="metadata-row">
          <div class="metadata-label">Parent Fingerprint Columns</div>
          <div class="metadata-value">{{ fingerprintSettings.parent_unique_id_columns.join(', ') }}</div>
        </div>
      </div>
    </div>
    <div v-else class="metadata-row">
      <div class="metadata-label">Override Children Fingerprint</div>
      <div class="metadata-value">
        <b-badge variant="success">No</b-badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FingerprintSettings',
  props: {
    uniqueId: {
      type: String,
      default: '',
    },
    fingerprintSettings: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.metadata-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.metadata-row {
  display: flex;
  margin-bottom: 5px;
}

.metadata-label {
  font-weight: bold;
  margin-right: 5px;
  width: 200px;
}

.metadata-value {
  flex: 1;
}
</style>
