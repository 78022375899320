<template>
  <b-container class="mt-4" v-if="loaded">
    <b-row class="mb-4">
      <b-col class="d-flex justify-content-start align-items-center">
        <h4>Eligibility Ingestion History</h4>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="7">
        <span>Ingestion history in filepath {{ filepath }} for onboarding client {{ onboarding_client_id }}</span>
      </b-col>
      <b-col cols="5">
        <b-input-group class="float-right">
          <b-form-input v-model="history.filename" placeholder="Search by filename..." v-on:keyup.enter="fetchData"/>
          <b-button v-if="history.items.length > 0" @click="fetchData" variant="info" class="float-right ml-2">
            Search
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table striped hover :items="history.items" :fields="fields" class="mt-2" id="history-ingestions-table">
          <template #cell(was_quarantined)="row">
            <b-badge v-if="row.item.was_quarantined" variant="danger">Yes</b-badge>
            <b-badge v-else variant="success">No</b-badge>
          </template>
          <template #cell(actions)="row">
            <b-button @click="openModalHandler(row.item)">Show Details</b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="history.currentPage"
          :total-rows="history.total"
          :per-page="history.perPage"
          aria-controls="history-ingestions-table"/>
      </b-col>
    </b-row>
    <b-modal
      ref="revision-details-modal"
      id="revision-details-modal"
      size="xl"
      :title="modalTitle"
      ok-only
      ok-variant="primary"
      ok-title="Close">
      <b-container fluid v-if="modal.open">
        <h5>Details</h5>
        <Metadata
          class="mt-4"
          :revision="modal.revision"/>
        <hr>
        <h5 class="mt-4">File Mapping
          <b-button class="btn-info ml-2 btn-sm" @click="() => toggles.mapping = !toggles.mapping" v-b-toggle.collapse-mapping>
            {{ toggles.mapping ? 'Hide' : 'Show' }} Mapping Configuration
          </b-button>
        </h5>
        <b-collapse id="collapse-mapping" class="mt-2">
          <Mapping class="mt-2" :mapping="modal.revision.metadata.mapping_information" v-if="hasMappingInfo(modal.revision)"/>
          <span v-else>No Mapping Information Available</span>
        </b-collapse>
        <hr>
        <h5 class="mt-2">Quality Check
          <b-button class="btn-info ml-2 btn-sm" @click="() => toggles.qualityCheck = !toggles.qualityCheck" v-b-toggle.collapse-quality-check>
            {{ toggles.qualityCheck ? 'Hide' : 'Show' }} Quality Check
          </b-button>
        </h5>
        <b-collapse id="collapse-quality-check" class="mt-2">
          <QualityCheck :data="convertToMetadata(modal.revision).quality_check" v-if="convertToMetadata(modal.revision).quality_check"/>
          <span v-else>No Quality Check Report Available</span>
        </b-collapse>
        <hr>
        <h5 class="mt-2">Quarantine
          <b-button class="btn-info ml-2 btn-sm" @click="() => toggles.quarantine = !toggles.quarantine" v-b-toggle.collapse-quarantine>
            {{ toggles.quarantine ? 'Hide' : 'Show' }} Quarantine
          </b-button>
        </h5>
        <b-collapse id="collapse-quarantine" class="mt-2">
          <Quarantine
            :configs="quarantineConfigs"
            :blockActions="true"
            :report="getQuarantineDetails(modal.revision)"
            v-if="modal.revision.quarantined_details"/>
          <span v-else>No Quarantine Report Available</span>
        </b-collapse>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import Report from '@/views/Onboarding/Quarantine/Eligibility/Report.vue';
import QualityCheck from '@/views/Onboarding/Eligibility/QualityCheck.vue';
import Mapping from '@/components/ClientEligibility/History/Mapping.vue';
import Metadata from '@/components/ClientEligibility/History/Metadata.vue';

export default {
  name: 'History',
  components: {
    Metadata,
    Mapping,
    QualityCheck,
    Quarantine: Report,
  },
  data() {
    return {
      onboarding_client_id: this.$route.params.clientId,
      filepath: this.$route.query.folder,
      loaded: false,
      toggles: {
        mapping: false,
        quarantine: false,
        qualityCheck: false,
      },
      modal: {
        open: false,
        revision: null,
      },
      quarantineConfigs: {},
      history: {
        filename: null,
        items: [],
        total: null,
        perPage: 10,
        currentPage: 1,
      },
      fields: [
        { key: 'revision', label: 'Revision' },
        { key: 'filename', label: 'Filename' },
        { key: 'number_of_lines', label: 'Number Of Lines' },
        { key: 'was_quarantined', label: 'Was Quarantined' },
        { key: 'created_at', label: 'Ingested At' },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'history.currentPage': function () {
      this.fetchData();
    },
  },
  computed: {
    modalTitle() {
      if (!this.modal.revision) {
        return '';
      }

      return `Ingestion Details for revision ${this.modal.revision.revision} for file ${this.modal.revision.filename}`;
    },
  },
  async beforeMount() {
    try {
      const { data } = await this.$store.dispatch('Sftp/Eligibility/getQuarantineConfigs', {
        onboarding_client_id: this.onboarding_client_id,
        filepath: this.filepath,
      });

      data.configs.forEach(c => {
        this.quarantineConfigs[c.name] = {
          uuid: c.uuid,
          name: c.name,
          label: c.label,
          value: c.value,
          default_value: c.default_value,
          operator: c.operator,
        };
      });

      await this.fetchData();
    } catch (err) {
      console.error(err);
      this.$noty.error(err?.response?.data?.message || 'Something went wrong');
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    async fetchData() {
      try {
        if (this.history.filename && this.history.filename.length < 3) {
          this.$noty.warn('Filename should be at least 3 characters');
          return;
        }

        this.loaded = false;
        const { data: history } = await this.$store.dispatch('Sftp/Eligibility/getEligibilityIngestionHistory', {
          onboarding_client_id: this.onboarding_client_id,
          filepath: this.filepath,
          limit: this.history.perPage,
          filename: this.history.filename,
          offset: (this.history.currentPage - 1) * this.history.perPage,
        });

        this.history.items = history.items;
        this.history.total = history.total;
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loaded = true;
      }
    },
    convertToMetadata(entry) {
      return {
        last_file_ingested: entry.filename,
        last_file_ingested_at: entry.created_at,
        last_file_number_of_lines: entry.number_of_lines,
        quality_check: entry?.quality_check,
      };
    },
    getQuarantineDetails(entry) {
      if (entry.quarantined_details) {
        return {
          quarantined: true,
          ...entry.quarantined_details,
        };
      }

      return {
        quarantined: false,
        ...entry.metadata.quarantine_report,
      };
    },
    openModalHandler(revision) {
      this.modal.revision = revision;
      this.modal.open = true;
      this.toggles = {
        mapping: false,
        quarantine: false,
        qualityCheck: false,
      };
      this.showModal();
    },
    showModal() {
      this.$refs['revision-details-modal'].show();
    },
    hasMappingInfo(revision) {
      return revision?.metadata?.mapping_information;
    },
  },
};
</script>
