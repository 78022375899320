<template>
  <div>
    <span>Subscriber</span>
    <MappingTable class="mt-4" :mapping="mappingTable.subscriber" />
    <div v-if="mappingTable.dependents.length > 0">
      <span>Dependents</span>
      <MappingTable :mapping="mappingTable.dependents" />
    </div>
    <span v-else>No dependent mapping available</span>
  </div>
</template>

<script>
import MappingTable from '@/components/ClientEligibility/History/MappingTable.vue';

export default {
  name: 'Mapping',
  components: { MappingTable },
  props: {
    mapping: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mappingTable: {
        subscriber: [],
        dependents: [],
      },
    };
  },
  beforeMount() {
    this.mappingTable.subscriber = this.convertSubscriberMappingToTable(this.mapping.subscriber);
    if (this.mapping.dependents && this.mapping.dependents.length > 0) {
      this.mappingTable.dependents = this.convertDependentMappingToTable(this.mapping.dependents);
    }
  },
  methods: {
    convertSubscriberMappingToTable(entry) {
      return Object.entries(entry).map(([ k, v ]) => ({
        name: k,
        ...v,
      }));
    },
    convertDependentMappingToTable(entry) {
      const tmpResult = {};
      // loop through each dependent mapping (if we have multiple)
      entry.forEach(e => {
        // loop through each key-value pair in the dependent mapping
        Object.entries(e).forEach(([ k, v ]) => {
          // if the key doesn't exist in the result object, create it
          if (!tmpResult[k]) {
            tmpResult[k] = {
              name: k,
              ...v,
            };
          } else {
            // if the key exists, append the column number to the existing value
            tmpResult[k].column_number = `${tmpResult[k].column_number}, ${v.column_number}`;
          }
        });
      });

      // convert the result object to an array
      return Object.values(tmpResult);
    },
  },
};
</script>
