<template>
  <div class="metadata-container">
    <div class="metadata-row">
      <div class="metadata-label">File Name:</div>
      <div class="metadata-value">{{ revision.filename }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Revision</div>
      <div class="metadata-value">{{ revision.revision }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Ingested At</div>
      <div class="metadata-value">{{ revision.created_at }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Number Of Lines</div>
      <div class="metadata-value">{{ revision.number_of_lines }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Ingestion With Errors</div>
      <div class="metadata-value">
        <b-badge :variant="getBadgeVariant(revision.has_errors)">
          {{getBadgeLabel(revision.has_errors)}}
        </b-badge>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Was Quarantined</div>
      <div class="metadata-value">
        <b-badge :variant="getBadgeVariant(revision.was_quarantined)">
          {{getBadgeLabel(revision.was_quarantined)}}
        </b-badge>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Fingerprint Settings</div>
      <div class="metadata-value" v-if="revision.unique_id_config">
        <FingerprintSettings
          :uniqueId="revision.unique_id_config"
          :fingerprintSettings="extractFingerprintSettings()"/>
      </div>
      <div class="metadata-value" style="color: red" v-else>No Fingerprint Settings</div>
    </div>
  </div>
</template>

<script>
import FingerprintSettings from '@/components/ClientEligibility/History/FingerprintSettings.vue';

export default {
  name: 'Metadata',
  components: {
    FingerprintSettings,
  },
  props: {
    revision: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    getBadgeVariant(val) {
      // eslint-disable-next-line default-case
      switch (val) {
        case 1:
        case true:
          return 'danger';
        case 0:
        case false:
          return 'success';
      }
    },
    // eslint-disable-next-line consistent-return
    getBadgeLabel(val) {
      // eslint-disable-next-line default-case
      switch (val) {
        case 1:
        case true:
          return 'Yes';
        case 0:
        case false:
          return 'No';
      }
    },
    extractFingerprintSettings() {
      if (this.revision?.metadata?.fingerprint_settings) {
        return this.revision.metadata.fingerprint_settings;
      }

      return null;
    },
  },
};
</script>

<style scoped>
.metadata-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.metadata-row {
  display: flex;
  margin-bottom: 5px;
}

.metadata-label {
  font-weight: bold;
  margin-right: 5px;
  width: 200px;
}

.metadata-value {
  flex: 1;
}
</style>
